<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">运营工具</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">班级归属管理</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">班级编码:</span>
              <el-input
                v-model="projectCode"
                clearable
                placeholder="请输入班级编码"
                size="small"
              ></el-input>
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">班级名称:</span>
              <el-input
                v-model="projectName"
                clearable
                placeholder="请输入班级名称"
                size="small"
              ></el-input>
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">机构名称:</span>
              <el-select
                size="small"
                v-model="compId"
                remote
                :remote-method="getCompanyList"
                filterable
                clearable
                placeholder="请至少输入两个字搜索"
              >
                <el-option
                  v-for="item in companyList"
                  :key="item.compId"
                  :label="item.compName"
                  :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
              clearable
              filterable
              v-model="areaId"
              :options="areatreeList"
              :props="propsarea"
              size="small"
            ></el-cascader>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="客服姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem">客服姓名:</span>
              <el-input
                v-model="customerServiceName"
                clearable
                placeholder="请输入客服姓名"
                size="small"
              ></el-input>
            </div>
            <div title="销售人员姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem"
                >销售人员姓名:</span
              >
              <el-input
                v-model="salesmanName"
                clearable
                placeholder="请输入销售人员姓名"
                size="small"
              ></el-input>
            </div>
            <div title="销售人员姓名" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">培训类型:</span>
              <el-cascader
                size="small"
                :props="{
                  emitPath: false,
                  value: 'id',
                  label: 'label',
                  children: 'children',
                  checkStrictly: true,
                }"
                v-model="trainTypeId"
                :options="trainTypeList"
                clearable
              ></el-cascader>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom:15px">
            <div title="指定销售人员" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:8rem"
                >指定销售人员:</span
              >
              <el-select
                size="small"
                v-model="notSpecifiedSalesMan"
                placeholder="请选择是否指定销售人员"
                clearable
              >
                <el-option label="全部" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="2"></el-option>
              </el-select>
            </div>
            <div title="指定客服" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">指定客服:</span>
              <el-select
                size="small"
                v-model="notSpecifiedcustomerService"
                placeholder="请选择是否指定客服"
                clearable
              >
                <el-option label="全部" value="0"></el-option>
                <el-option label="是" value="1"></el-option>
                <el-option label="否" value="2"></el-option>
              </el-select>
            </div>
            <div class="df" style="padding:0 10px">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
                >查询</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="setPersonnel('10')"
                >设置销售人员</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="setPersonnel('20')"
                >设置客服</el-button
              >
              <el-button
                type="primary"
                class="bgc-bv"
                round
                @click="exportProjectAscription()"
                >导出</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              row-key="projectId"
              style="width: 100%"
              @selection-change="checkListData"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                type="selection"
                width="50px"
                align="center"
                fixed
                :reserve-selection="true"
                :selectable="selectable"
              ></el-table-column>
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
              />
              <el-table-column
                label="班级编码"
                align="left"
                show-overflow-tooltip
                prop="projectCode"
                min-width="200px"
                fixed
              />
              <el-table-column
                label="班级名称"
                align="left"
                show-overflow-tooltip
                prop="projectName"
                min-width="200px"
              />
              <el-table-column
                label="培训类型"
                align="left"
                show-overflow-tooltip
                prop="trainTypeName"
                min-width="200"
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                min-width="200"
              />
              <el-table-column
                label="班级状态"
                align="left"
                show-overflow-tooltip
                prop="endState"
                min-width="80"
              />
              <el-table-column
                label="机构名称"
                align="left"
                show-overflow-tooltip
                prop="compName"
                min-width="200"
              />
              <el-table-column
                label="销售人员"
                align="left"
                show-overflow-tooltip
                prop="marketName"
                min-width="80"
              />
              <el-table-column
                label="客服"
                align="left"
                show-overflow-tooltip
                prop="assistantName"
                min-width="80px"
              />
              <el-table-column
                label="是否免费"
                align="center"
                width="80px"
                fixed="right"
              >
                <div slot-scope="scope">
                  <el-switch
                    :width="20"
                    v-model="scope.row.freeProject"
                    @change="
                      getSwitchUsable(
                        scope.row.projectId,
                        scope.row.freeProject
                      )
                    "
                  ></el-switch>
                  <span>{{ scope.row.freeProject ? "是" : "否" }}</span>
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <el-dialog
      :title="dialogTitle"
      :visible.sync="dialogVisible"
      width="45%"
      center
      top="5%"
      @close="doClose"
    >
      <div class="ovy-a">
        <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          label-width="8rem"
          class="demo-ruleForm"
          style="width: 90%"
        >
          <el-form-item
            :label="stu == '10' ? '设置销售人员' : '设置客服'"
            prop="saleId"
            required
          >
            <el-select
              v-model="ruleForm.saleId"
              placeholder="请选择类型"
              size="small"
              clearable
            >
              <el-option
                v-for="item in options"
                :key="item.saleId"
                :label="item.saleName"
                :value="item.saleId"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <div style="display: flex; justify-content: center">
          <el-button @click="doClose">取消</el-button>
          <el-button class="bgc-bv" @click="submitForm">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { mapGetters } from "vuex";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "workOrder/classOwnership",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      //班级编码
      projectCode: "",
      //班级名称
      projectName: "",
      //机构Id
      compId: "",
      //行政区划
      areaId: "",
      //销售人员姓名
      salesmanName: "",
      //客服姓名
      customerServiceName: "",
      //未指定销售人员
      notSpecifiedSalesMan: "",
      //未指定客服人员
      notSpecifiedcustomerService: "",
      //培训类型
      trainTypeId: "",
      //机构名称下拉数据
      companyList: [],
      dialogVisible: false,
      ruleForm: {},
      rules: {
        saleId: [
          {
            required: true,
            message: "请选择" + this.stu == "10" ? "销售人员" : "客服人员",
            trigger: "blur",
          },
        ],
      },
      //弹框title
      dialogTitle: "设置销售人员",
      //勾选的数据
      multipleTable: [],
      //销售人员-客服下拉数据
      options: [],
      stu: "",
      trainTypeList: [],
       //行政区划下拉数据
      areatreeList:[],
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  watch: {},
  created() {
    this.getTraintype();
    this.getareatree()
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
      userJson: "getUser",
    }),
  },
  mounted() {
    this.getTableHeight();
  },
  methods: {
    //获取培训类型
    getTraintype() {
      this.$post("/sys/category/train-type/tree").then((ret) => {
        if (ret.status == "0") {
          this.trainTypeList = ret.data;
        }
      });
    },
    //获取行政区划
    getareatree() {
      this.$post("/sys/area/tree").then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    // 获取 - 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
          .then((res) => {
            if (res.status == 0) {
              this.companyList = res.data || [];
            }
          })
          .catch(() => {
            return;
          });
      } else {
        this.companyList = [];
      }
    },
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.salesmanName) {
        params.marketName = this.salesmanName;
      }
      if (this.customerServiceName) {
        params.assistantName = this.customerServiceName;
      }
      if (this.notSpecifiedcustomerService) {
        params.appointAssistant = this.notSpecifiedcustomerService;
      }
      if (this.notSpecifiedSalesMan) {
        params.appointMarket = this.notSpecifiedSalesMan;
      }

      this.doFetch({
        url: "/biz/project/queryProjectAscriptionPage",
        params,
        pageNum,
      });
    },
    // 勾选列表数据
    checkListData(rowArr) {
      this.multipleTable = rowArr;
    },
    //设置销售人员&&客服人员
    setPersonnel(stu) {
      this.stu = stu;
      if (this.multipleTable.length != 0) {
        this.dialogVisible = true;
        if (stu == "20") {
          this.dialogTitle = "设置客服";
        }
        this.$post("/biz/user/sale/selectList", {
          saleType: stu,
          saleName: "",
        }).then((ret) => {
          this.options = ret.data;
        });
      } else {
        this.$message({
          message: "请至少勾选一项!",
          type: "warning",
        });
      }
    },
    //确定
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let api = "/biz/project/setAssistantMarket";
          let projectIds = [];
          projectIds = this.multipleTable.map((t) => {
            return t.projectId;
          });
          let params = {
            marketAssistantId: this.ruleForm.saleId, //客服或销售id
            type: this.stu,
            projectIds,
          };
          this.$post(api, params).then((ret) => {
            if (ret.status == "0") {
              this.getData(-1);
              this.$refs.multipleTable.clearSelection();
              this.doClose();
            }
          });
        } else {
          return false;
        }
      });
    },
    doClose() {
      this.ruleForm = {};
      this.$refs["ruleForm"].resetFields();
      this.dialogVisible = false;
    },

    // 是否免费
    getSwitchUsable(projectId, freeProject) {
      this.$post("/biz/project/setProjectfree", {
        freeProject,
        projectId,
      })
        .then((ret) => {
          this.$message({
            type: "success",
            message: ret.message,
          });
          this.getData(-1);
        })
        .catch((err) => {
          return;
        });
    },
    //导出
    exportProjectAscription() {
      const params = {
      };
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.trainTypeId) {
        params.trainTypeId = this.trainTypeId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.salesmanName) {
        params.marketName = this.salesmanName;
      }
      if (this.customerServiceName) {
        params.assistantName = this.customerServiceName;
      }
      if (this.notSpecifiedcustomerService) {
        params.appointAssistant = this.notSpecifiedcustomerService;
      }
      if (this.notSpecifiedSalesMan) {
        params.appointMarket = this.notSpecifiedSalesMan;
      }
      this.$post('/biz/project/exportProjectAscription',params).then(ret => {
        // window.open(ret.data)
         if (ret.status == "0") {
                let list = ret.data;
                if (!this.downloadItems.includes(list.taskId)) {
                  this.$store.dispatch("pushDownloadItems", list.taskId);
                } else {
                  this.$message.warning(
                    "[" + list.fileName + "]已经申请下载,请耐心等待"
                  );
                }
            }else {
                this.$message.error(ret.message);
              }
      })
    },
    getTableHeight(opDom = true, page = true) {
      let tHeight =
        window.innerHeight - (3 + 1.35 + 3.25 + 0.675 + 2 + 1.35 + 5) * 16;
      if (opDom) {
        tHeight -= 40 + 0.675 * 16 + 1;
      }
      if (page) {
        tHeight -= 40;
      }
      this.tableHeight = tHeight;
      // // 40为thead高 | trHeight默认45为带按钮的tbody的tr高
      // this.pageSize = Math.ceil((tHeight -40) / trHeight);
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less" scope>
.upload-btns {
  height: 40px;
  .el-upload {
    height: 40px !important;
    border: none !important;
  }
}
.Score-ruleForm {
  .el-form-item {
    display: flex;
    align-items: center;
  }
}
</style>
